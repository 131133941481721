import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { useState } from 'react';

const TopBar = () => {
  let [scrolled, setScrolled] = useState(false);

  document.onscroll = () => {
    // optimisation so react wouldnt have to re-render everytime the user scrolls
    if (!(scrolled && document.documentElement.scrollTop != 0)) {
      setScrolled(document.documentElement.scrollTop != 0)
    }
  }

  return (
    <div className={`fixed top-0 transition-all ${scrolled ? 'p-5' : 'p-10'} w-full
    ${scrolled && 'bg-black border-b-2'}
    flex flex-col gap-5 md:flex-row md:gap-0 place-content-between
    text-white z-30`}>

      <Link to='/'>
        <h1 className='font-extrabold t text-3xl underline decoration-red-500 text-center md:text-left'>KomixApix</h1>
      </Link>
      <div className='lg:space-x-32 md:space-x-16 space-x-5 place-self-center font-semibold'>
        <Link to='/mangas'>Mangas</Link>
        <Link to='/animations'>Animations</Link>
        <Link to='/about'>About</Link>
      </div>
      <div className='mr-3 place-self-center absolute top-12 right-10 md:relative md:top-0 md:right-0'>
        <FontAwesomeIcon icon={faShoppingCart} size='lg' />
      </div>
    </div>
  );
}

export default TopBar;