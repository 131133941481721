import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TopBar from './TopBar';
import Home from './Home';

const AppRoutes = () => {
  return <>
    <BrowserRouter>
      <TopBar />
      <Routes>
        <Route path='/' element={ <Home/> } />
        <Route path='/hello' element={ <>hi</> }/>
      </Routes>
    </BrowserRouter>
  </>;
}

export default AppRoutes;