import './HomeTopSection.css';

const HomeTopSection = (props) => {
  return (
    <div className='p-14
    md:h-screen w-full
    flex flex-col lg:flex-row justify-evenly gap-10 md:gap-0
    top-section-gradient'>

      <div className='w-48
        pt-28 md:pt-0
        text-white text-5xl font-black
        self-center'>
        NEWEST
        MANGA
        RELEASE
      </div>

      <div className='
      text-white
      flex flex-col gap-10 md:gap-0 md:flex-row self-center
      md:max-w-[80vw] lg:max-w-[65vw] md:max-h-[60vh]'>

        <img
          className='mr-10 ml-10 object-contain max-h-[50vh] md:max-h-[60vh] w-auto md:max-w-[30vw]'
          src={props.coverImg}
          alt={props.coverImgAlt} />

        <div>
          <h3 className='text-3xl font-bold'>{props.displayName}</h3>
          <div className='text-white text-sm text-opacity-75 mt-2'>Stock: {props.stockCount}</div>
          <p className='mt-4 font-light'>{props.description}</p>

          <div className='mt-8 flex'>
            <div className='buy-button
            pt-3 pb-3 pl-5 pr-5 rounded-full
            flex items-center gap-2
            cursor-pointer' onClick={props.onBuy}>

              <div className='font-light text-sm'>{props.displayPrice}</div>
              <div className='w-[2px] h-[75%] bg-gray-300 opacity-50'></div>
              <div className='font-light'>BUY NOW</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeTopSection;