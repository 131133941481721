import HomeTopSection from './HomeTopSection';

const Home = () => {
  return <>
    <section>
      <HomeTopSection
        displayName='DIABLO FAMILIA I'
        coverImg='assets/df1.png'
        coverImgAlt='Diabo Familia I'
        onBuy={() => console.log('buy')}
        displayPrice='Rp. 1,000'
        stockCount={69} 
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique enim, tempor, felis rutrum vel nec lacinia cursus. Tortor odio cursus elit volutpat tincidunt molestie senectus dui, maecenas. Congue bibendum velit in ac posuere. Duis pretium elementum, ullamcorper morbi sit erat in fames. Neque urna ultrices eget ornare dui. Volutpat lorem at ultrices feugiat. Sem non feugiat consequat imperdiet est sagittis lacinia malesuada. Ultricies nunc nisl massa mattis id nibh eget. Nisl porttitor egestas rhoncus, feugiat iaculis tellus nulla mi. Eget ornare.' /> 
    </section>
    <section className='h-screen w-full flex justify-center items-center'>
      hi
    </section>
  </>;
}

export default Home;